.footer {
  background-color: #6C757D;
  color: white;
  padding: 40px 10px;
  text-align: center;
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
}

.company-details h2 {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
}

.quick-links h3,
.contact-details h3 {
  font-size: 20px;
  margin-bottom: 15px;
  border-bottom: 2px solid #007BFF;
  display: inline-block;
}

.quick-links ul,
.contact-details p {
  list-style: none;
  padding: 0;
  margin: 0;
}

.quick-links li a,
.contact-details p a,
.legal-policies a {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
  padding: 5px 0;
  display: block;
}

.quick-links li a:hover,
.contact-details p a:hover,
.legal-policies a:hover {
  color: #007BFF;
}

.phone-icon::before {
  content: '📞';
  margin-right: 5px;
}

.email-icon::before {
  content: '📧';
  margin-right: 5px;
}

.copyright {
  margin-top: 40px;
  font-size: 14px;
  border-top: 1px solid #007BFF;
  padding-top: 20px;
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .company-details,
  .quick-links,
  .contact-details {
    width: 100%;
    margin-bottom: 20px;
  }
}

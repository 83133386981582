.aboutus-section {
  background-color: #F8F9FA;
  padding: 50px 0;
}

.aboutus-header {
  color: #007BFF;
  text-align: center;
  font-size: 2em;
  margin-bottom: 30px;
}

.about_us_header{
  color: #6C757D;
  text-align: center;
}

.aboutus-content {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-around;
  text-align: justify;
}

.aboutus-intro {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  color: #6C757D;
  margin: 10px;
}

.card {
  background-color: #fff;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  flex: 1;
  margin: 10px;
}

.our-story-card,
.directors-note-card,
.services-card {
  color: #6C757D;
}

.directors-note-card {
  background-color: rgba(0, 123, 255, 0.1); /* Soft blue background */
}

/* Mobile Responsiveness */
@media (max-width: 768px) {
  .aboutus-content {
      flex-direction: column;
  }

  .card {
      margin-bottom: 20px;
  }
}

.custom-cards-container {
    display: flex;
    
    gap: 20px;
    justify-content: space-around;
    padding: 20px;
}

.custom-card {
    background-color: #ffffff; /* default white, will be overridden by inline styles */
    border-radius: 10px;
    color: #ffffff;
    padding: 20px;
    width: calc(33.333% - 20px);
    position: relative;
    overflow: hidden;
    max-width: 300px;
    display: flex;
    text-align: center;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 0 10px 2px RGBA(0,0,0,0.4) !important;
    transition: 0.2s all ease-in-out;
}

.custom-card:hover {

    scale: 1.2;
    transition: 0.2s all ease-in-out;
    z-index: 999;
}

.card-icon {
    display: flex;
    font-size: 3rem;
    margin-bottom: 10px;
    text-align: center;
    background-color: #007BFF;
    width: 50px;
    height: 50px;
    box-shadow: 0 0 10px 2px RGBA(0,0,0,0.4) !important;
    padding: 1rem;
    border-radius: 50%;
}

.custom-card h3 {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.custom-card p {
    font-size: 1rem;
}

/* Mobile Responsiveness */
@media (max-width: 1068px) {
    .custom-card {
        width: calc(80% - 20px);
        
        

    }

    .custom-cards-container{
        flex-wrap: wrap;
        

        
        
    }
}

@media (max-width: 480px) {
    .custom-card {
        width: 100%;


    }
}

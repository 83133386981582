.services-section {
  background-color: white;
  padding: 50px 0;
  text-align: center;
  max-width: 95%;
  align-items: center;
  justify-content: center;
  align-self: center;
}

.services-header {
  color: #007BFF;
  margin-bottom: 50px;
  font-size: 2rem;
}

.wrapper_main{
  justify-content: center;
  align-items: center;
  display: flex;
 
}

.service-card {
  padding: 20px;
  color: white;
  border-radius: 10px;
  max-width: 50%;
  background-color: rgba(32,171,197, 1); /* Adjust according to your color scheme */
  text-align: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.service-icon {
  background-color: #007BFF;
  width: 50px;
  height: 50px;
  box-shadow: 0 0 10px 2px RGBA(0,0,0,0.4) !important;
  padding: 1rem;
  border-radius: 50%;
  font-size: 3rem;
}

.icon-text-area {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.service-title {
  font-weight: bold;
  
  font-size: 1.8rem;
}

.service-list {
  font-size: 1.2rem;
  font-weight: 500;
  list-style:disc;
  padding-left: 1rem;
  
  text-align: left;
}

.service-item {
  margin-bottom: 10px;
}

/* Responsive styling */
@media (max-width: 768px) {
  .service-card {
    width: 90%;
    max-width: 85%;
    margin: 0 auto;
  }

  .wrapper_main{
    align-items: center;
  }

  .services-section {
    max-width: 85%;
  }
}

.navbar {
  display: flex;
  justify-content: space-between;
  backdrop-filter: blur(5px);
  align-items: center;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: rgba(32,171,197, 1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar-header a {
  color: whitesmoke;
  text-decoration: none;
  font-weight: bold;
  font-size: 1.5rem;
  margin-left: 2rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-transform: uppercase;
}

.hamburger-menu {
  font-size: 2rem;
  cursor: pointer;
  margin-right: 1rem;
  color: whitesmoke;
}

.dropdown-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: absolute;
  top: 100%;
  left: 0;
  border-bottom-right-radius: 1rem;
  border-bottom-left-radius: 1rem;
  backdrop-filter: blur(100px) !important;
  right: 0; 
  background-color: rgba(32,171,197, 0.8);
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
}

.dropdown-menu.slideUp {
  animation: slideUp 0.3s forwards;
  display: flex; /* Or you could use visibility: visible; */
}

.dropdown-menu.slideDown {
  animation: slideDown 0.3s forwards;
  animation-fill-mode: forwards;
}

.dropdown-link {
  padding: 10px 20px;
  color: whitesmoke;
  text-decoration: none;
  
  background-color: #007bff;
 
  margin: 0.5rem;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
}

.dropdown-link:hover {
  background-color: #083b72;
  font-weight: 800;
  transition: all 0.1s ease;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  80% { /* 80% keyframe for bounce effect */
    transform: translateY(10%);
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0%);
    opacity: 1;
  }
  to {
    transform: translateY(-100%);
    opacity: 0;
  }
}


@media (max-width: 768px) {
  .navbar-header a {
    font-size: 1.2rem;
    text-transform: uppercase;
  }
}

.contact-form-section {
    background: linear-gradient(180deg, #007BFF, #0056B3);
    color: white;
    padding: 40px 20px;
    text-align: center;
  }
  
  .contact-form-section h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
  }
  
  form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    max-width: 500px;
    margin: 0 auto;
  }
  
  input, textarea {
    padding: 10px;
    border: none;
    border-radius: 5px;
  }
  
  button {
    background-color: #28A745;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #218F3C;
  }
  
  .direct-contact {
    margin-top: 40px;
  }
  
  /* Mobile Responsiveness */
  @media (max-width: 768px) {
    form {
      max-width: 100%;
    }
  }
  